.main-1{
    background-color: rgba(255, 255, 255, 0.423);
    display: flex;
    justify-content: space-between;
    border: 1px #001c55 solid;
    align-items: center;
    width: 84%;
    border-radius: 15px;
    margin-inline: auto;
    position: relative;
    overflow: hidden;
    margin-top: 13px;
    padding-top: 20px;
    margin-bottom: 23px;
}


.contact-main-new{
    padding-left: 70px;
    z-index: 1;
}

.Heading-1{
    font-size: 50px;
    font-weight: bold;
}

.Heading-2{
    font-size: 18px;
    max-width: 37ch;
    opacity: 0.6;
}

.Form_Data{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 37ch;

}

.Form_Data button{
    font-size: 18px;
    background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
    padding: 14px 20px;
    border-radius: 30px;
    color: white;
    margin-top: 30px;
    margin-bottom: 55px;
    border: none;
    cursor: pointer;
    width: 100%;
}

.Form_Data button:hover{
    background: linear-gradient(to top right, #00ff00 -5%, #fbfbfb 100%);
    opacity: 0.8;
    color: black;
}

.Form_Data input{
    padding: 8px 20px;
    margin: 8px 0;
    display: inline-block;
    border: none;
    font-size: 18px;
    background: transparent;
    box-sizing: border-box;
    border-bottom: 1px gray solid;
    outline: none;
}

.Form_Data input:focus{
    border-bottom: 2px blue solid;
    
}

.contact-info{
    background-color: rgb(45,45,45);
    color:  white;
    padding: 10px;
    border-radius: 24px 0 0 24px;
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding-left: 45px;
    padding-top: 45px;
    padding-bottom: 45px;
    z-index: 1;
    margin-right: -10px;
    max-width: 35%;
}

.Social-Add{
    display: flex;
    align-items: center;
    gap: 13px;
    font-size: 18px;
}

.Social-Add a{
    color: white;
    text-decoration: none;
    font-size: 20px;
}

.Social-Add h3{
    font-size: 18px;
    font-weight: normal;
    max-width: 48ch;
}

.location{
    margin-top: -20px;
}

.contact-info h2{
    font-size: 36px;
}

.BackLine{
    position: absolute;
    top: 0;
    right: 0;
    background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%);
    height: 100%;
    width: 20%;
}

.Social-Contact{
    display: grid;
    place-items: center;
    margin-bottom: 15px;
}

.Sample-or-contact{
    text-align: center;
    margin-bottom: 5px;
    margin-top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-weight: bold;
    color: rgb(0, 0, 0);
}

.LIne{
    width: 30%;
    height: 1px;
    background-color: #004de7;
}


@media (max-width:768px) {
    .main-1{
        flex-direction: column;
    }
    .BackLine{
        display: none;
    }
    .contact-main-new{
        justify-content: center;
        text-align: center;
        padding-left: 0px;
    }
    .Form_Data{
        margin-inline: auto;
    }
    .contact-info{
        display: none;
    }
}

@media (max-width: 400px){
    .Form_Data{
        width: 80%;
    }
}